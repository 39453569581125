import React, { useEffect, useState } from 'react';
import "./KlleonChat.css";

const KlleonChatSelf = () => {
    const [message, setMessage] = useState(''); // State to hold the message to be echoed

    useEffect(() => {
        // Create script element to load Klleon Chat SDK
        const script = document.createElement('script');
        script.src = 'https://sdk.klleon.io/klleon-chat/0.9.0/klleon_chat_sdk.js';
        script.async = true;

        // Append script to the body
        document.body.appendChild(script);

        // Initialize Klleon Chat SDK once the script has loaded
        script.onload = () => {
            const sdkOption = { sdk_key: 'APP-WJwvCzoYkWZbqqBrrNjM' };
            const avatarOption = {
                avatar_id: 'a5fe629d-0090-11ef-8ee1-0abbf354c5cc',
                voice_code: "en_us",
                subtitle_code: "en_us",
                voice_tts_speech_speed: 0.8
            };
            const chatOption = { type: 'inside', align: 'end' };

            if (window.KlleonChat) {
                window.KlleonChat.init(sdkOption);
                window.KlleonChat.showStreaming(avatarOption);
                window.KlleonChat.showChatUi(chatOption);
            } else {
                console.error('KlleonChat is not defined.');
            }
        };

        // Cleanup function to remove script from the DOM
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const handleEcho = () => {
        if (window.KlleonChat) {
            if (typeof window.KlleonChat.echo === 'function') {
                // Call the echo method and handle the response
                try {
                    const result = window.KlleonChat.echo(message);
                    if (result && typeof result.then === 'function') {
                        result
                            .then(response => {
                                console.log("Message echoed:", response);
                            })
                            .catch(error => {
                                console.error("Error echoing message:", error);
                            });
                    } else {
                        console.error('Echo method did not return a promise.');
                    }
                } catch (error) {
                    console.error("Exception occurred while echoing message:", error);
                }
            } else {
                console.error('KlleonChat.echo is not a function or is not defined.');
            }
        } else {
            console.error('KlleonChat is not defined.');
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: 'lightcoral', padding: '24px', height: '100vh' }}>
            <div id="klleon_chat"></div>
            <div style={{ marginLeft: '20px', display: 'flex', flexDirection: 'column' }}>
            <textarea
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Type your message here"
        style={{
            marginBottom: '10px',
            padding: '8px',
            borderRadius: '4px',
            resize: 'vertical',
            height: '150px',
            width: '300px'
        }}
    />
                <button
                    className="bg-lightGreen w-full h-[48px] rounded-[6px] text-white font-bold"
                    onClick={handleEcho}
                >
                    Echo Message
                </button>
            </div>
        </div>
    );
};

export default KlleonChatSelf;


