import axios from 'axios';
import { BASE_URL } from '../../Constants';
import authHeader from './AuthHeader';
// Base URL for your API
const API_URL = BASE_URL;



class AuthServices {
    async signUp(body) {
        return axios.post(API_URL + "signup/", body);
    }

    async signIn(body) {
        return axios.post(API_URL + "login/", body);
    }

    async refreshToken(body) {
        return axios.post(API_URL + "refresh-token/", body, { headers: authHeader() });
    }

    logout() {
        localStorage.removeItem("user");
        window.location.href = '/'
    }
}

export default new AuthServices();