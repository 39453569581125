import React, { useState, useEffect } from 'react';

const CircularProgressBar = ({ progress, strokeWidth, achievedScore, totalScore }) => {
  const [size, setSize] = useState(180);
  const [showProgress, setShowProgress] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setSize(window.innerWidth >= 1024 ? 245 : 180);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => setShowProgress(true), 100);

    return () => clearTimeout(timer);
  }, []);

  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const arcLength = (circumference * 5) / 7;
  const emptyArc = circumference - arcLength;
  const strokeDashoffset = arcLength - (progress / 100) * arcLength;

  return (
    <div className="flex items-center justify-center relative">
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        className="transform rotate-[143deg]"
      >
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={strokeWidth / 3}
          className="text-neutral-11"
          stroke="currentColor"
          fill="transparent"
          strokeDasharray={`${arcLength} ${circumference}`}
          strokeLinecap="round"
        />
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={strokeWidth}
          className={`text-primary-1 ${showProgress ? 'transition-stroke-dashoffset' : ''}`}
          stroke="currentColor"
          fill="transparent"
          strokeDasharray={`${arcLength} ${circumference}`}
          strokeDashoffset={showProgress ? strokeDashoffset : arcLength + 143}
          strokeLinecap="round"
          style={{ transition: 'stroke-dashoffset 2s ease' }}
        />
      </svg>

      <div className="absolute flex flex-col gap-[10px]">
        <p className='text-center font-open_sans text-font_color-primary font-bold text-[24px]'>{achievedScore}/{totalScore}</p>
        <p className='text-center body-1 text-font_color-body'>{progress}%</p>
      </div>
    </div>
  );
};

export default CircularProgressBar;




