import React from "react";
import AdminSidebar from "../SideBar/AdminSidebar";
import { AdminContent } from "./AdminContent";
import Header from "../../SubComponents/Header";



export const AdminMain = () => {
    return (
        <div className="h-[100vh] w-[100vw] flex flex-col overflow-hidden">
            <Header background={"bg-primary-1"} />
            <div className="flex flex-grow overflow-hidden">
                <div className="hidden lg:block m-5">
                    <AdminSidebar />
                </div>
                <AdminContent />
            </div>
        </div>
    )
}