import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const AdminProtected = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const isLoggedIn = useSelector((state) => state.signin_reducer.isLoggedIn);

    if ((!isLoggedIn && !user) || (user && user.user.role !== 3)) {
        return <Navigate to="/" replace />;
    }
    return <Outlet />;
};

export default AdminProtected;