import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { SigninSignupImage } from "../SubComponents/SignupSigninImage";
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from "react-redux";
import { resetSignupState, signUp } from "../Redux/Actions/AuthAction/SignUpAction";
import { getGenders } from "../Redux/Actions/UserActions";
import { getRoles } from "../Redux/Actions/UserActions";
import { TailSpin } from 'react-loader-spinner';
import VerificationResend from "../SubComponents/VerificationResend";
import Header from "../SubComponents/Header";



export const SignUp = () => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [showResend, setShowRensend] = useState(false);
    const dispatch = useDispatch();
    const roles = useSelector((state) => state.role_reducer.getRoles);
    const signUpStatus = useSelector((state) => state.signup_reducer.signUp);


    const studentRole = roles && roles !== "isLoading" && roles.length !== 0 && roles.result.length > 0 && roles.result.find(role => role.name === "Student");
    const studentRoleId = studentRole ? studentRole.role_id : null;
    const [formValues, setFormValues] = useState({
        "email": "",
        "first_name": "",
        "last_name": "",
        "role": "",
        "password": ""
    });
    const [errors, setErrors] = useState({});
    // const datePickerRef = useRef(null);

    // Toggle function
    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };
    // const openDatePicker = () => {
    //     datePickerRef.current.setOpen(true);
    // };
    useEffect(() => {
        dispatch(getGenders());
        dispatch(getRoles());
    }, [dispatch]);
    useEffect(() => {
        setFormValues(prevValues => ({
            ...prevValues,
            role: studentRoleId
        }));
    }, [studentRoleId]);

    useEffect(() => {
        if (signUpStatus && signUpStatus !== "isLoading" && signUpStatus.status === true) {
            setShowRensend(true);
            setFormValues({
                "email": "",
                "first_name": "",
                "last_name": "",
                "role": studentRoleId,
                "password": ""
            });
        } else {
            setShowRensend(false);
        }
    }, [signUpStatus]);

    // const handleSelectChange = (event) => {
    //     const { name, value } = event.target;

    //     setFormValues(prevState => ({
    //         ...prevState,
    //         [name]: value
    //     }));

    // };

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    };

    // useEffect(() => {
    //     signUpStatus && signUpStatus !== "isLoading" && signUpStatus.status === true &&

    // }, [signUpStatus, navigate]);



    const validateForm = () => {
        const newErrors = {};
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (!formValues.first_name.trim()) newErrors.first_name = "First Name is required.";
        if (!formValues.last_name.trim()) newErrors.last_name = "Last Name is required.";
        if (!formValues.email.trim()) newErrors.email = "Email is required.";
        else if (!/\S+@\S+\.\S+/.test(formValues.email)) newErrors.email = "Email is invalid.";
        // if (!formValues.dob) newErrors.dob = "Date of Birth is required.";
        // if (!formValues.gender) newErrors.gender = "Gender is required.";
        if (!formValues.password.trim()) newErrors.password = "Password is required.";
        else if (!passwordRegex.test(formValues.password)) newErrors.password = "Password must include: length greater than 8 characters, at least 1 uppercase, 1 numeric value and 1 special character";

        return newErrors;
    };

    const handleCreateAccount = () => {
        const formErrors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            const body = formValues;
            dispatch(signUp(body));
        } else {

            setErrors(formErrors);
        }
    };


    // const handleDobChange = (newDate) => {
    //     setDateOfBirth(newDate);
    //     setFormValues((prevValues) => ({
    //         ...prevValues,
    //         dob: dayjs(newDate).format('YYYY-MM-DD')
    //     }));
    // };

    const handleClose = () => {
        dispatch(resetSignupState());
    };
    return (

        <div className="p-0 m-0 w-full h-screen lg:w-screen lg:h-screen lg:flex lg:p-5">

            <div className="hidden lg:flex lg:flex-1 items-center justify-end">
                <SigninSignupImage />
            </div>
            <div className="lg:hidden">
                <Header background={"bg-primary-1"} />
            </div>

            <div className="w-full py-4 px-4 text-font_color-primary lg:flex-1 flex flex-col items-center justify-center lg:pl-24 lg:pr-[14%] lg:gap-4 lg:max-w-[50vw]">
                <div className="w-full flex flex-col gap-4">
                    <div className="w-full flex flex-col gap-[8px] items-center lg:items-start lg:justify-between lg:gap-4">
                        <h2 className="Heading-24 lg:Heading-48 text-left">
                            Create an account
                        </h2>
                        <div className="flex flex-row lg:flex-row gap-2 lg:gap-2 items-center">
                            <p className="body-2 lg:body-1 text-font_color-body">
                                Already have an account?
                            </p>
                            <a
                                href="/signin"
                                className="subtitle2 text-primary-1 cursor-pointer underline hover:text-primary-5 lg:subtitle1"
                            >
                                Log In
                            </a>
                        </div>
                    </div>
                    {showResend === true && (
                        <VerificationResend
                            text="A verification link has been sent to your email, please verify."
                            onClose={handleClose} />
                    )}

                    <div className="w-full flex flex-col h-[70vh] gap-6 justify-between lg:h-fit">
                        <div className="flex flex-col gap-[16px] lg:gap-[24px]">
                            <div className="w-full flex flex-col gap-2 font-open_sans">
                                <label className="subtitle2 lg:subtitle1">
                                    First Name
                                </label>
                                <div className="w-full h-[38px] bg-white border border-[#DFE4EA] rounded-[6px] flex items-center gap-[10px]">
                                    <input
                                        type="text"
                                        name="first_name"
                                        placeholder="Enter your first name"
                                        value={formValues.first_name}
                                        onChange={handleChange}
                                        className="w-full h-full bg-transparent  p-[8px_16px] border-none outline-none"
                                    />
                                </div>
                                {errors.first_name && <p className="text-red-500 text-sm">{errors.first_name}</p>}
                            </div>

                            <div className="w-full flex flex-col gap-2 font-open_sans">
                                <label className="subtitle2 lg:subtitle1">
                                    Last Name
                                </label>
                                <div className="w-full h-[38px] bg-white border border-[#DFE4EA] rounded-[6px]  flex items-center gap-[10px]">
                                    <input
                                        type="text"
                                        name="last_name"
                                        placeholder="Enter your last name"
                                        value={formValues.last_name}
                                        onChange={handleChange}
                                        className="w-full h-full bg-transparent  p-[8px_16px] border-none outline-none px-4"
                                    />
                                </div>
                                {errors.last_name && <p className="text-red-500 text-sm">{errors.last_name}</p>}
                            </div>

                            <div className="w-full flex flex-col gap-2 font-open_sans">
                                <label className="subtitle2 lg:subtitle1">
                                    Email
                                </label>
                                <div className="w-full h-[38px] bg-white border border-[#DFE4EA] rounded-[6px] flex items-center gap-[10px]">
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Enter your email"
                                        value={formValues.email}
                                        onChange={handleChange}

                                        className="w-full h-full bg-transparent p-[8px_16px] border-none outline-none"
                                    />
                                </div>
                                {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                            </div>

                            <div className="w-full flex flex-col gap-2 font-open_sans">
                                <label className="subtitle2 lg:subtitle1">
                                    Password
                                </label>
                                <div className="relative w-full h-[38px] bg-white border border-[#DFE4EA] rounded-[6px] flex items-center">
                                    <input
                                        type={isPasswordVisible ? 'text' : 'password'}
                                        name="password"
                                        value={formValues.password}
                                        onChange={handleChange}
                                        placeholder="Enter your password"
                                        className="w-full h-full bg-transparent border-none outline-none p-[8px_16px] rounded-[6px]"
                                    />
                                    <div
                                        className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                                        onClick={togglePasswordVisibility} // Toggle visibility on click
                                    >
                                        {isPasswordVisible ? (
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
                                                <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                                                <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                                                <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-eye"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                            </svg>
                                        )}
                                    </div>
                                </div>
                                {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}
                            </div>
                        </div>

                        <div className="flex flex-col gap-[12px] lg:gap-[24px]">
                            <button
                                className="bg-primary-1 w-full rounded-[6px] hover:bg-primary-5 button2 px-[20px] py-[10px] text-neutral-1 flex items-center justify-center lg:py-[13px] lg:button1"
                                onClick={handleCreateAccount}
                            >
                                {signUpStatus === "isLoading" ? (
                                    <TailSpin
                                        color="#FFFFFF"
                                        height={20}
                                        width={20}

                                    />
                                ) : (
                                    "Create account"
                                )
                                }


                            </button>
                            <div className="w-full flex items-center justify-center gap-[10px] lg:gap-[18px]">
                                <hr className="border-t border-[#8C8C8C] w-[90px] lg:w-[100px]" />
                                <p className="text-center text-[#111928] font-open_sans leading-[21.79px] text-[12px] lg:text-[16px]">or continue with</p>
                                <hr className=" border-t border-[#8C8C8C] w-[90px] lg:w-[100px]" />

                            </div>
                            <div className="w-full flex items-center justify-center gap-[24px]">
                                <button className="w-[240px] h-[48px] px-[var(--sds-size-space-300)] py-[var(--sds-size-space-300)] gap-[var(--sds-size-space-200)] rounded-[6px] border border-[#8C8C8C] flex items-center justify-center">
                                    <img src="/google_icon.svg" alt="Google Icon" className="w-6 h-6 mr-2" />
                                    Google
                                </button>

                                <button className="w-[240px] h-[48px] px-[var(--sds-size-space-300)] py-[var(--sds-size-space-300)] gap-[var(--sds-size-space-200)] rounded-[6px] border border-[#8C8C8C] flex items-center justify-center">
                                    <img src="/facebook_icon.svg" alt="Facebook Icon" className="w-6 h-6 mr-2" />
                                    Facebook
                                </button>

                            </div>
                            <footer className="body-3 text-font_color-body text-center lg:hidden">
                                <p>© 2024 Knnect. All Rights Reserved.</p>
                            </footer>
                        </div>
                    </div>
                </div>
                <footer className="hidden lg:block text-font_color-body body-1 text-center relative bottom-0">
                    <p>© 2024 Knnect. All Rights Reserved.</p>
                </footer>
            </div>
        </div>
    );
}