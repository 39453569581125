import React, { useEffect } from "react";
import CourseProgressCard from "./CourseProgressCard";
import PerformanceScoreCard from "./PerformanceScoreCard";
import NumbersCards from "./NumbersCards";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardData } from "../../Redux/Actions/UserActions";
import { TailSpin } from 'react-loader-spinner';
import OngoingSessions from "./OngoingSessions";


export const Dashboard = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const dashboardData = useSelector((state) => state.dashboard_data_reducer.getDashboardData);
    useEffect(() => {
        const body = {
            "user_id": user.user._id
        }
        dispatch(getDashboardData(body));
    }, [])
    const handleExploreCourses = () => {
        navigate("/app/courses")
    }
    return (
        <>
            {dashboardData && dashboardData === "isLoading" && (
                <div className='flex items-center justify-center h-full'>
                    <TailSpin color="#34D795" height={50} width={50} />
                </div>
            )}
            {dashboardData && dashboardData !== "isLoading" && dashboardData.length !== 0 && (
                <div className="flex flex-col gap-[24px] text-font_color-primary lg:gap-[40px] mx-[15px]">
                    <div className="flex flex-col gap-[8px]">
                        <h3 className=" text-left Heading-24 lg:Heading-32">
                            {`Welcome back, ${user.user.first_name}!`}
                        </h3>
                        <p className="text-font_color-body text-left body-2 lg:body-00">Ready to conquer your next lesson?</p>
                    </div>
                    {dashboardData.length !== 0 && dashboardData.result && dashboardData.result.length !== 0 && (
                        <>
                            <div className="flex flex-col gap-[16px] lg:gap-[24px] lg:flex-row">
                                <div className="lg:flex-1 lg:order-1 order-2">
                                    <CourseProgressCard courseProgressData={dashboardData.result.courses} />
                                </div>
                                <div className="lg:flex-1 lg:order-2 order-3">
                                    <PerformanceScoreCard progressScoreData={dashboardData.result} />
                                </div>
                                <div className="lg:flex lg:order-3 order-1">
                                    <NumbersCards numberData={dashboardData.result} />
                                </div>
                            </div>

                            <OngoingSessions
                                sessionsData={dashboardData.result.all_ongoing_sessions}
                            />
                        </>
                    )}
                    {dashboardData && dashboardData.result && dashboardData.result.length === 0 && (
                        <div className="flex flex-col gap-[40px] items-center justify-center lg:flex-row lg:h-[500px]">
                            <img
                                className="w-[150px] h-[150px] lg:w-[300px] lg:h-[300px]"
                                src="/dashboard_empty.svg"
                                alt="dashboard empty"
                            />
                            <div className="flex flex-col gap-[32px] items-center lg:items-start">
                                <div className="flex flex-col gap-[8px]">
                                    <p className="text-font_color-primary text-center Heading-18 lg:Heading-24 lg:text-left">Nothing to show!</p>
                                    <p className="text-font_color-body text-center body-2 lg:body-1 lg:text-left lg:w-[515px]">You haven’t enrolled in any courses. Start learning by exploring our course catalog and finding the perfect match for you!</p>
                                </div>
                                <button
                                    onClick={handleExploreCourses}
                                    className="button2 px-[16px] py-[10px] gap-[10px] bg-primary-1 text-neutral-1 rounded-[6px] hover:bg-primary-4 w-fit lg:button1"
                                >
                                    Explore Courses
                                </button>
                            </div>
                        </div>

                    )}
                </div >
            )}
        </>
    )
}