import axios from "axios";
import AuthServices from "./Auth/AuthServices";

let isRefreshing = false;
let failedQueue = []; 

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (token) {
            prom.resolve(token);
        } else {
            prom.reject(error);
        }
    });
    failedQueue = [];
};

export const Interceptor = () => {
    return axios.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            const originalRequest = error.config;

            if (error.response && error.response.status === 401) {
                if (isRefreshing) {
                    return new Promise((resolve, reject) => {
                        failedQueue.push({ resolve, reject });
                    })
                    .then(token => {
                        originalRequest.headers['Authorization'] = 'Bearer ' + token;
                        return axios(originalRequest);
                    })
                    .catch(err => Promise.reject(err));
                }

                isRefreshing = true;

                const user = JSON.parse(localStorage.getItem("user"));
                if (!user) {
                    handleLogout();
                    return Promise.reject(error);
                }

                const device_id = localStorage.getItem("deviceId");
                const body = {
                    refresh_token: user.tokens.RefreshToken.token,
                    device_id,
                };

                return AuthServices.refreshToken(body)
                    .then(response => {
                        if (response.data) {
                            const newAccessToken = response.data.result.AccessToken;
                            const newAccessTokenExpiry = response.data.result.AccessTokenExpiry;
                            const updatedUser = {
                                ...user,
                                tokens: {
                                    ...user.tokens,
                                    AccessToken: newAccessToken,
                                    AccessTokenExpiry: newAccessTokenExpiry,
                                },
                            };
                            localStorage.setItem("user", JSON.stringify(updatedUser));
                            axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
                            processQueue(null, newAccessToken);
                            originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                            return axios(originalRequest);
                        }
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 400) {
                            handleLogout();
                        }
                        processQueue(err, null);
                        return Promise.reject(err);
                    })
                    .finally(() => {
                        isRefreshing = false;
                    });
            }

            return Promise.reject(error);
        }
    );
};


const handleLogout = () => {
    AuthServices.logout();
}