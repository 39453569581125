// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import { Provider } from "react-redux";
// import store from "./store";
// import reportWebVitals from './reportWebVitals';
// import { BrowserRouter } from "react-router-dom";
// import {
//   CssBaseline,
//   ThemeProvider,
//   createTheme,
//   StyledEngineProvider,
// } from "@mui/material";


// const muiTheme = createTheme();
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <BrowserRouter>
//     <StyledEngineProvider injectFirst>
//       <ThemeProvider theme={muiTheme}>
//         <CssBaseline />
//         <App />
//       </ThemeProvider>
//     </StyledEngineProvider>
//   </BrowserRouter>
// );

// reportWebVitals();

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './store'; // Make sure this path is correct
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material';

// Create a theme instance
const muiTheme = createTheme();

// Get the root element from the DOM
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the application
root.render(
  <Provider store={store}> {/* Wrap with Provider */}
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  </Provider>
);

reportWebVitals();

