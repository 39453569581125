import React, { useRef, useEffect, useState } from 'react';
import { SessionCard } from '../MyLearning/SessionCard';

const OngoingSessions = ({ sessionsData }) => {
    const carouselRef = useRef(null);
    const [leftButtonDisabled, setLeftButtonDisabled] = useState(true);
    const [rightButtonDisabled, setRightButtonDisabled] = useState(true);

    const handleScroll = () => {
        if (!carouselRef.current) return;
        const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
        setLeftButtonDisabled(scrollLeft <= 0);
        setRightButtonDisabled(scrollLeft + clientWidth >= scrollWidth);
    };

    const checkContentOverflow = () => {
        if (!carouselRef.current) return;
        const { scrollWidth, clientWidth } = carouselRef.current;
        if (scrollWidth > clientWidth) {
            setRightButtonDisabled(false);
        } else {
            setLeftButtonDisabled(true);
            setRightButtonDisabled(true);
        }
    };

    const handleScrollLeft = () => {
        if (carouselRef.current) {
            carouselRef.current.scrollBy({ left: -300, behavior: 'smooth' });
        }
    };

    const handleScrollRight = () => {
        if (carouselRef.current) {
            carouselRef.current.scrollBy({ left: 300, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const carousel = carouselRef.current;

        if (carousel) {
            checkContentOverflow();
            carousel.addEventListener('scroll', handleScroll);
            window.addEventListener('resize', checkContentOverflow);
        }

        return () => {
            if (carousel) {
                carousel.removeEventListener('scroll', handleScroll);
            }
            window.removeEventListener('resize', checkContentOverflow);
        };
    }, []);

    return (
        <div className="flex flex-col gap-[24px] lg:gap-[16px]">
            <div className="flex flex-row justify-between">
                <p className="text-left Heading-18 lg:Heading-24">Ongoing Sessions</p>

                <div className="hidden lg:flex gap-[16px]">
                    <button
                        className={`w-[46px] h-[46px] rounded-full shadow-sessioncard flex items-center justify-center ${leftButtonDisabled ? 'opacity-50 cursor-not-allowed bg-neutral-5' : ''
                            }`}
                        onClick={handleScrollLeft}
                        disabled={leftButtonDisabled}
                    >
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M11 5L4 12L11 19M4 12H20"
                                stroke="#2F2F2F"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                    <button
                        className={`w-[46px] h-[46px] rounded-full shadow-sessioncard flex items-center justify-center ${rightButtonDisabled ? 'opacity-50 cursor-not-allowed bg-neutral-5' : ''
                            }`}
                        onClick={handleScrollRight}
                        disabled={rightButtonDisabled}
                    >
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M3.5 12H20M20 12L13 5M20 12L13 19"
                                stroke="#2F2F2F"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                </div>
            </div>
            {/* <div className="flex"> */}
                <div className="relative w-full">
                    <div
                        ref={carouselRef}
                        className="flex flex-wrap lg:p-2 gap-[24px] lg:overflow-x-auto lg:scroll-smooth lg:flex-nowrap"
                        style={{
                            scrollbarWidth: 'none',
                            msOverflowStyle: 'none',
                        }}
                    >
                        {sessionsData.map((session, index) => (
                            <SessionCard key={index} sessionData={session} />
                        ))}
                    </div>
                </div>
            {/* </div> */}
        </div>
    );
};

export default OngoingSessions;
