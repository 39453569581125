import React from "react";




export const Settings = () => {
    return (
        <div className="text-lg text-black">
            Settings
        </div>
    )
}