import React from "react";
import { TailSpin } from 'react-loader-spinner';


const StudentsTable = ({ studentsData }) => {
    return (
        <div className="border border-neutral-4 rounded-[8px] overflow-x-auto" style={{
            boxShadow: "0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A"
        }}>
            <table className="w-full text-left text-font_color-body">
                <thead className="subtitle2 bg-[#F9FAFB]">
                    <tr>
                        <th scope="col" class="px-[16px] py-[12px]">
                            Student
                        </th>
                        <th scope="col" class="px-[16px] py-[12px]">
                            Email
                        </th>
                        <th scope="col" class="px-[16px] py-[12px]">
                            Overall Score
                        </th>
                        <th scope="col" class="px-[16px] py-[12px]">

                        </th>
                    </tr>
                </thead>
                <tbody className="bg-neutral-1">
                    {studentsData && studentsData !== "isLoading" && studentsData.length !== 0 && studentsData.result && studentsData.result.length !== 0 && studentsData.result.all_students.map((student) => (
                        <tr
                            // key={i}
                            className="border-b border-neutral-4">
                            <td className="flex items-center p-[16px] gap-[10px] text-inter font-medium text-sm whitespace-nowrap">
                                <img
                                    src={student.profile_image ? student.profile_image : "/avatar.png"}
                                    className="h-[32px] w-[32px] rounded-[200px]"
                                />
                                <p>{student.first_name + " " + student.last_name}</p>
                            </td>
                            <td className="p-[16px] text-inter font-normal text-sm whitespace-nowrap">
                                {student.email}
                            </td>
                            <td className="p-[16px] text-inter font-medium text-sm whitespace-nowrap">
                                <div className="flex gap-[5px] items-center">
                                    <div className="flex-grow bg-neutral-4 rounded-full h-[9px] max-w-[100px]">
                                        <div className="bg-primary-1 h-[9px] rounded-full" style={{ width: `${student.achieved}%` }}></div>
                                    </div>
                                    <p className="subtitle3 text-right text-font_color-primary">{student.achieved_score}/<span className="text-font_color-body">{student.total_score}</span> </p>
                                </div>
                            </td>
                            <td className="py-[16px] px-[40px] gap-[10px] text-inter font-medium text-sm whitespace-nowrap">
                                <button className="subtitle2 text-left text-primary-1 underline hover:text-primary-5">View Detail</button>
                            </td>
                        </tr>
                    ))}
                    {studentsData && studentsData === "isLoading" && (
                        <tr className="border-b border-neutral-4">
                            <td colSpan={4} className="text-center py-[16px]">
                                <div className="flex justify-center">
                                    <TailSpin color="#34D795" height={25} width={25} />
                                </div>
                            </td>
                        </tr>
                    )}


                </tbody>
            </table>
        </div>

    )
}
export default StudentsTable;
