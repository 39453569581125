import React from "react";
import { useNavigate } from "react-router-dom";


export const SessionCard = ({ sessionData }) => {
    console.log(sessionData);
    const navigate = useNavigate();
    const completionPercentage = (sessionData.lessons_completed / sessionData.total_lessons) * 100;
    const handleResume = () => {
        const sessionId = sessionData.session_id;
        const teacherId = sessionData.teacher.teacher_id;
        const lessonId = sessionData.current_lesson._id;
        navigate(`/app/my-learning/enrolled-course/my-lesson/${sessionId}/${teacherId}/${lessonId}`);
    
    };
    return (
        <div className="h-[246px] w-full p-[20px] shadow-sessioncard flex justify-center items-center rounded-[16px] lg:w-[400px]">
            <div className="flex flex-col justify-between lg:w-[335px] h-[182px]">
                <div className="flex flex-col gap-[16px]">
                    <h5 className="font-montserrat font-semibold text-font_color-primary text-[16px] lg:text-[18px]">{sessionData.current_lesson.name}</h5>
                    <div className="flex items-center gap-[8px]">
                        <img
                            className="h-[24px] w-[24px] rounded-full"
                            alt="teacher"
                            src={sessionData.teacher.image_url}
                        />
                        <p className="subtitle3 text-font_color-body lg:subtitle2">{sessionData.teacher.teacher_name}</p>
                    </div>
                </div>
                <div className="flex flex-col gap-[12px]">
                    <div className="flex gap-[16px] items-center">
                        <div className="flex-grow bg-neutral-4 rounded-full h-2">
                            <div className="bg-primary-1 h-2 rounded-full" style={{ width: `${completionPercentage}%` }}></div>
                        </div>
                        <p className="body-2 text-right text-font_color-primary lg:body-1">{sessionData.lessons_completed}/<span className="text-font_color-body">{sessionData.total_lessons} Completed </span> </p>
                    </div>
                    <div className="flex items-center justify-between">
                        <p className="body-2 text-right text-font_color-body lg:body-1">Overall Score: <span className="text-font_color-primary">9</span> out 10</p>
                        <button
                            onClick={handleResume}
                            className='border border-2 border-primary-1 py-[8px] px-[16px] text-primary-1 button3 rounded-[6px] bg-neutral-1 lg:button2 hover:text-primary-4 lg:py-[8px] lg:px-[16px]'>{sessionData.lessons_completed === 0 ? "Start" : "Resume"}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}