import { combineReducers } from 'redux';
import signup_reducer from './AuthReducer/SignUpReducer';
import signin_reducer from './AuthReducer/SignInReducer';
import role_reducer from './RolesReducer';
import gender_reducer from './GendersReducer';
import sidebar_reducer from './SidebarSelectionReducer';
import lesson_content_reducer from './LessonContentReducer';
import teachers_reducer from './TeachersReducer';
import courses_reducer from './CoursesReducer';
import course_enrollment_reducer from './CourseEnrollmentReducer';
import course_detail_reducer from './CourseDetailReducer';
import forgot_password_reducer from './ForgotPasswordReducer';
import update_password_reducer from './UpdatePasswordReducer';
import user_courses_reducer from './UserCoursesReducer';
import user_course_detail_reducer from './UserCourseDetailReducer';
import create_session_reducer from './CreateSessionReducer';
import dashboard_data_reducer from './DashboardDataReducer';
import profile_reducer from './ProfileReducer';
import send_verification_email_reducer from './SendVerificationEmailReducer';
import lessonReducer from './lessonSlice';
import ModalReducer from './LeaveAvatarSlice';
import admin_dashboard_data_reducer from './AdminDashboardDataReducer';
import all_student_reducer from './AllStudentsReducer';


export default combineReducers({
  signup_reducer,
  signin_reducer,
  role_reducer,
  gender_reducer,
  sidebar_reducer,
  lesson_content_reducer,
  teachers_reducer,
  courses_reducer,
  course_enrollment_reducer,
  course_detail_reducer,
  forgot_password_reducer,
  update_password_reducer,
  user_courses_reducer,
  user_course_detail_reducer,
  create_session_reducer,
  dashboard_data_reducer,
  profile_reducer,
  send_verification_email_reducer,
  lesson: lessonReducer,
  AvatarModal: ModalReducer,
  admin_dashboard_data_reducer,
  all_student_reducer
  
});
