import { SEND_VERIFICATION_EMAIL_FAIL, SEND_VERIFICATION_EMAIL_PENDING, SEND_VERIFICATION_EMAIL_SUCCESS } from "../Actions/Types";

const initialState = {
    sendVerificationEmail: [],
};
const send_verification_email_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SEND_VERIFICATION_EMAIL_SUCCESS:
            return {
                ...state,
                sendVerificationEmail: payload.sendVerificationEmail,
            };
        case SEND_VERIFICATION_EMAIL_PENDING:
            return {
                ...state,
                sendVerificationEmail: payload.sendVerificationEmail,
            };
        case SEND_VERIFICATION_EMAIL_FAIL:
            return {
                ...state,
                sendVerificationEmail: [],
            };

        default:
            return state;
    }
};
export default send_verification_email_reducer;