import React, { useState, useRef, useEffect } from 'react';

const AccordionItem = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [maxHeight, setMaxHeight] = useState('0px');
    const contentRef = useRef(null);

    useEffect(() => {
        if (isOpen) {
            setMaxHeight(`${contentRef.current.scrollHeight}px`);
        } else {
            setMaxHeight('0px');
        }
    }, [isOpen]);

    return (
        <div className="w-full border rounded-lg overflow-hidden shadow-md">
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="flex items-center justify-between w-full text-left button3 p-[16px] bg-neutral-1 text-font_color-body lg:Heading-18 lg:p-[32px]"
            >
                <span>{title}</span>
                <span className="flex items-center">
                    {isOpen ? (
                        <span className="text-base font-bold">—</span>
                    ) : (
                        <svg
                            className="transition-transform duration-200"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M7 10l5 5 5-5"
                                stroke="#8C8C8C"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    )}
                </span>
            </button>
            <div
                ref={contentRef}
                className="transition-[max-height,opacity] duration-300 ease-out overflow-hidden"
                style={{ maxHeight, opacity: isOpen ? 1 : 0 }}
            >
                <div className=" bg-white text-left text-font_color-body px-[16px] pb-[16px] body-3 lg:body-0 lg:px-[32px] lg:pb-[32px]">
                    {content}
                </div>
            </div>
        </div>
    );
};

export default AccordionItem;