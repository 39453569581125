import React from 'react';

const VerificationResend = ({ onClose, text }) => {
    return (
        <div className=" flex justify-between items-center px-[16px] py-[10px] gap-[12px] rounded-[8px] bg-primary-2 text-font_color-body shadow-[0px_1px_4px_0px_rgba(0,0,0,0.12)] transition-opacity duration-300 ease-out">
            <p className='subtitle3 text-font_color-body'>
                {text}
            </p>
            <div className='flex gap-[12px]'>
                <button className="  text-primary-1 rounded-[6px] bg-neutral-1 py-[6px] px-[16px] lg:button2">
                    Resend
                </button>
                <button
                    className="p-[1.05px]"
                    onClick={onClose}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                    </svg>
                </button>
            </div>

        </div>
    );
};

export default VerificationResend;