import { SIGNIN_FAIL, SIGNIN_PENDING, SIGNIN_SUCCESS, RESET_SIGNIN } from "../Types"
import AuthServices from "../../../Services/Auth/AuthServices"
import { toast } from "react-toastify"



export const signIn = (body) => async (dispatch) => {
    dispatch({
        type: SIGNIN_PENDING,
        payload: { signIn: "isLoading" },
    });
    return await AuthServices.signIn(body)
        .then(
            (data) => {
                if (data.data.status === true) {
                    localStorage.setItem('user', JSON.stringify(data.data.result));
                    dispatch({
                        type: SIGNIN_SUCCESS,
                        payload: { signIn: data.data },
                    })
                }
                else {
                    dispatch({
                        type: SIGNIN_FAIL,
                    });
                    toast.error(data.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                return Promise.resolve();
            },
            (error) => {
                const status = error.response ? error.response.status : null;
                if (status && status !== 200) {
                    toast.error(
                        "Something went wrong",
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                }
                dispatch({
                    type: SIGNIN_FAIL,
                });

                return Promise.reject();
            }
        )
        .catch(() => {
            toast.error(
                "Something went wrong",
                {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            );
        });
};


export const resetSignIn = () => async (dispatch) => {
    dispatch({
        type: RESET_SIGNIN,
    });
}