import React, { useEffect, useState } from "react";
import CourseEnrolementCard from "./CourseEnrolementCard";
import { useDispatch, useSelector } from "react-redux";
import { getCourses } from "../../Redux/Actions/UserActions";
import { useNavigate } from "react-router-dom";
import { TailSpin } from 'react-loader-spinner';


export const Courses = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const allCourses = useSelector((state) => state.courses_reducer.getCourses);

    const handleExploreCourseClick = (courseId) => {
        navigate("/app/course-detail", { state: { course_id: courseId } });
    };

    useEffect(() => {
        dispatch(getCourses());
    }, [])
    return (
        <div className="w-full flex flex-col text-font_color-primary gap-[24px] lg:gap-[40px] px-[10px]">
            <h3 className="Heading-18 lg:Heading-24">Courses</h3>
            {allCourses && allCourses === "isLoading" && (
                <div className='flex items-center justify-center h-full'>
                    <TailSpin color="#34D795" height={50} width={50} />
                </div>
            )}
            <div className="flex flex-wrap justify-center gap-[16px] lg:justify-start">
                {
                    allCourses && allCourses !== "isLoading" && allCourses.length !== 0 && allCourses.result && allCourses.result.length > 0 &&
                    allCourses.result.map((course) => (
                        <CourseEnrolementCard
                            key={course.id}
                            course={course}
                            onExploreCourseClick={handleExploreCourseClick}
                        />
                    ))}
            </div>
            {allCourses && allCourses !== "isLoading" && allCourses.length !== 0 && allCourses.result && allCourses.result.length === 0 && (
                <div className='flex items-center justify-center h-full'>
                    No Course Available
                </div>
            )}
        </div>
    )
}