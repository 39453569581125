import React, { useState } from "react";
import { SigninSignupImage } from "../SubComponents/SignupSigninImage";
import { useDispatch, useSelector } from "react-redux";
import { TailSpin } from 'react-loader-spinner';
import VerificationResend from "../SubComponents/VerificationResend";
import { forgotPassword, resetForgotPassword } from "../Redux/Actions/UserActions";
import Header from "../SubComponents/Header";
import { useEffect } from "react";


export const ForgotPassword = () => {
    const [formValues, setFormValues] = useState({
        email: ''
    });
    const [errors, setErrors] = useState({});
    const [showResend, setShowRensend] = useState(false);
    const dispatch = useDispatch();
    const forgotPasswordStatus = useSelector((state) => state.forgot_password_reducer.forgotPassword);

    const validateForm = () => {
        let valid = true;
        const newErrors = { email: '' };

        // Email validation
        if (!formValues.email.trim()) {
            newErrors.email = 'Email is required';
            valid = false;
        } else if (!/\S+@\S+\.\S+/.test(formValues.email.trim())) {
            newErrors.email = 'Invalid email format';
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
        setErrors({});
    };

    const handleSendResetLink = () => {
        const formErrors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            const body = {
                "email": formValues.email.trim()
            }
            dispatch(forgotPassword(body));

        } else {
            // Set errors to display validation messages
            setErrors(formErrors);
        }
    };
    const handleClose = () => {
        dispatch(resetForgotPassword());
    };
    useEffect(() => {
        if (forgotPasswordStatus && forgotPasswordStatus !== "isLoading" && forgotPasswordStatus.status === true) {
            setShowRensend(true);
            setFormValues({ email: '' });
        } else {
            setShowRensend(false);
        }
    }, [forgotPasswordStatus]);
    return (
        <div className="p-0 m-0 w-full h-screen lg:w-screen lg:h-screen lg:flex lg:p-5">

            <div className="hidden lg:flex lg:flex-1 items-center justify-end">
                <SigninSignupImage />
            </div>

            <div className="lg:hidden w-full flex">
                <Header background={"bg-primary-1"} />
            </div>

            <div className="w-full py-4 px-4 text-font_color-primary lg:flex-1 flex flex-col items-center justify-center lg:pl-24 lg:pr-[14%] lg:gap-4 lg:max-w-[50vw]">
                {/* <div className="flex flex-col text-font_color-primary lg:w-[504px] lg:gap-[40px]"> */}
                <div className="w-full flex flex-col text-font_color-primary gap-[24px] lg:gap-[40px]">
                    <div className="w-full flex flex-col gap-[8px] items-center lg:items-start lg:justify-between lg:gap-4">
                        <h2 className="Heading-24 lg:Heading-48 text-left">
                            Forgot Password?
                        </h2>
                        <p className="body-2 text-font_color-body text-center lg:body-1 lg:text-left">
                            No worries! Enter your email, and we'll help you reset it quickly.
                        </p>

                    </div>
                    {showResend === true && (
                        <VerificationResend
                            text="A password reset link has been sent to your email."
                            onClose={handleClose} />
                    )}
                    <div className="w-full flex flex-col gap-6">
                        <div className="w-full flex flex-col gap-[16px] lg:gap-[24px]">
                            <label className="subtitle2 lg:subtitle1">
                                Email
                            </label>
                            <div className="w-full h-10 bg-white border border-[#DFE4EA] rounded-[6px] flex items-center">
                                <input
                                    type="text"
                                    name="email"
                                    placeholder="Enter your email"
                                    value={formValues.email}
                                    onChange={handleInputChange}
                                    className="w-full h-full bg-transparent  p-[8px_16px] border-none outline-none"
                                />
                            </div>
                            {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                        </div>

                        <button
                            className="bg-primary-1 w-full rounded-[6px] button2 px-[20] py-[10px] text-neutral-1 flex items-center justify-center lg:py-[13px] lg:button1"
                            onClick={handleSendResetLink}
                        >
                            {forgotPasswordStatus === "isLoading" ? (
                                <TailSpin color="#FFFFFF" height={20} width={20} />
                            ) : (
                                "Send Reset Link"
                            )}
                        </button>
                    </div>
                    <footer className="body-3 text-font_color-body text-center lg:hidden">
                        <p>© 2024 Knnect. All Rights Reserved.</p>
                    </footer>
                </div>
                <footer className="hidden lg:block text-font_color-body body-1 text-center relative bottom-0">
                    <p>© 2024 Knnect. All Rights Reserved.</p>
                </footer>
            </div>

        </div>
    );
}