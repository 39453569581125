import axios from 'axios';
import { BASE_URL } from '../Constants';
import authHeader from './Auth/AuthHeader';
import { Interceptor } from './Interceptor';

// Base URL for your API
const API_URL = BASE_URL;


class UserServices {
    async getGenders() {
        return axios.get(API_URL + "genders/");
    }

    async getRoles() {
        return axios.get(API_URL + "roles/");
    }

    async getCourses() {
        return axios.get(API_URL + "courses/");
    }

    async getCourseDetail(id) {

        return axios.get(API_URL + "course-details/" + id,
        );
    }

    async forgotPassword(body) {
        return axios.post(API_URL + "send-forgot-password-verification-email/", body,
        );
    }

    async updatePassword(body) {
        return axios.post(API_URL + "update-password/", body,
        );
    }

    async getLessonsContent(body) {
        Interceptor();
        return axios.post(API_URL + "get-lesson/", body, { headers: authHeader() }
        );
    }

    async getUserCourses(body) {
        Interceptor();
        return axios.post(API_URL + "user-courses/", body, { headers: authHeader() }
        );
    }

    async getUserCourseDetail(body) {
        Interceptor();
        return axios.post(API_URL + "user-course-detail/", body, { headers: authHeader() }
        );
    }

    async enrollCourse(body) {
        Interceptor();
        return axios.post(API_URL + "user_enrollments/create/", body, { headers: authHeader() }
        );
    }

    async createSession(body) {
        Interceptor();
        return axios.post(API_URL + "create-session/", body, { headers: authHeader() }
        );
    }

    async getDashboardData(body) {
        Interceptor();
        return axios.post(API_URL + "get-dashboard-data/", body, { headers: authHeader() }
        );
    }

    async getAdminDashboardData() {
        Interceptor();
        return axios.get(API_URL + "admin-dashboard-data/", { headers: authHeader() }
        );
    }
    async getAllStudents(body) {
        Interceptor();
        return axios.post(API_URL + "all-students/", body, { headers: authHeader() }
        );
    }
    async getProfile() {
        Interceptor();
        return axios.get(API_URL + "get-profile/", { headers: authHeader() }
        );
    }

    async sendVerificationEmail(body) {
        return axios.post(API_URL + "send-verification-email/", body, { headers: authHeader() }
        );
    }

    async getTeachers() {
        return axios.get(API_URL + "teachers/"
        );
    }
}


const userServicesInstance = new UserServices();

export default userServicesInstance;