import React from "react";

const NumbersCards = ({ numberData }) => {
    return (
        <div className="flex text-font_color-primary gap-[16px] lg:gap-[24px] lg:h-[375px] lg:flex-col">
            <div className="flex flex-col w-full h-full border border-neutral-4 rounded-[10px] p-[16px] gap-[16px] items-center justify-center lg:p-[24px] lg:gap-[24px]">
                <p className="text-center Heading-18 lg:Heading-40">
                    {numberData.total_courses}
                </p>
                <p className="text-center text-font_color-body button2 lg:Heading-18">
                    Courses Enrolled
                </p>
            </div>
            <div className="flex flex-col w-full h-full border border-neutral-4 rounded-[10px] p-[16px] gap-[16px] items-center justify-center lg:p-[24px] lg:gap-[24px]">
                <p className="text-center Heading-18 lg:Heading-40">
                    {numberData.all_ongoing_sessions_length}
                </p>
                <p className="text-center text-font_color-body button2 lg:Heading-18">
                    Ongoing Sessions
                </p>
            </div>
        </div>
    )
}
export default NumbersCards;