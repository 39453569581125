import React, { useEffect } from 'react';
import "./KlleonChat.css"

const KlleonChatComponent = () => {
    useEffect(() => {
        // Declare script variable in the effect scope
        const script = document.createElement('script');
        script.src = 'https://sdk.klleon.io/klleon-chat/0.9.0/klleon_chat_sdk.js';
        script.async = true;
        document.body.appendChild(script);
        // Initialize and configure Klleon Chat SDK after the script has loaded
        script.onload = () => {
            const sdkOption = { sdk_key: 'APP-WJwvCzoYkWZbqqBrrNjM' };
            const avatarOption = { avatar_id: 'a5fe629d-0090-11ef-8ee1-0abbf354c5cc',
                voice_code: "en_us", subtitle_code: "en_us"
             };
            const chatOption = { type: 'inside' };

            if (window.KlleonChat) {
                window.KlleonChat.init(sdkOption);
                window.KlleonChat.showStreaming(avatarOption);
                window.KlleonChat.showChatUi(chatOption);
            }
        };

        // Cleanup function to remove the script from the DOM
        return () => {
            if (document.body.contains(script)) {
                document.body.removeChild(script);
            }
        };
    }, []);


    return (
        <div style={{display:'flex', flexDirection:'row', backgroundColor:'lightcoral', padding:'24px', height:'100vh'}}>
        {/* <div style={{width:'50vw', backgroundColor:'lightgreen'}}></div> */}
        <div id="klleon_chat"></div>
        </div>
    );
};

export default KlleonChatComponent

