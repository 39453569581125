import React, { useEffect, useRef, useState } from "react";
import HeroSection from "./LandingPageComponents/HeroSection";
import CourseList from "./LandingPageComponents/CourseList";
import MeetAITeachers from "./LandingPageComponents/MeetAITeachers";
import FutureLearning from "./LandingPageComponents/FutureLearning";
import AskedQuestions from "./LandingPageComponents/AskedQuestions";
import StartLearning from "./LandingPageComponents/StartLearning";
import LandingPageFooter from "./LandingPageComponents/LandingPageFooter";
import { useNavigate } from "react-router-dom";
import Header from "../SubComponents/Header";

export const LandingPage = () => {
  const animatedRefs = useRef([]);
  const animatedOnce = useRef([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const [headerBackground, setHeaderBackground] = useState('transparent');

  const setRefs = (index) => (el) => {
    animatedRefs.current[index] = el;
  };

  useEffect(() => {
    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        const index = animatedRefs.current.indexOf(entry.target);
        if (entry.isIntersecting && !animatedOnce.current[index]) {
          entry.target.classList.add(
            entry.target.dataset.animationType === "left"
              ? "animate-slide-in-left"
              : "animate-slide-in-right"
          );
          animatedOnce.current[index] = true;
        }
      });
    };

    const observerOptions = {
      threshold: 0.4,
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    animatedRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      animatedRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);
  const handleKnnectClick = () => {
    if (user) {
      navigate("/app/dashboard");
    } else {
      navigate("/signup");
    }

  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setHeaderBackground('bg-primary-1');
      } else {
        setHeaderBackground('transparent');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <div className="relative w-full">
      <div className="fixed top-0 left-0 z-10 w-full">
        <Header background={headerBackground} />
      </div>

      {/* Main Content */}
      <div className="pt-[header-height]">
        <HeroSection onClick={handleKnnectClick} />
        <MeetAITeachers setRefs={setRefs} onClick={handleKnnectClick} />
        <CourseList setRefs={setRefs} />
        <FutureLearning setRefs={setRefs} onClick={handleKnnectClick} />
        <AskedQuestions />
        <StartLearning onClick={handleKnnectClick} />
        <LandingPageFooter />
      </div>
    </div>
  );
};
