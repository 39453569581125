import React from "react";
import CircularProgressBar from "./CircularProgressBar";

const PerformanceScoreCard = ({ progressScoreData }) => {
    return (
        <div className="flex flex-col rounded-[10px] p-[16px] text-font_color-primary gap-[16px] border border-neutral-4 lg:gap-[24px] lg:p-[24px] lg:h-[375px]">
            <p className="text-left Heading-18">
                Performance Score
            </p>
            <div className="flex flex-col gap-[9px]">
                <CircularProgressBar
                    progress={progressScoreData.percentage_of_score}
                    // size={245}
                    // size="180 lg:[245]"
                    strokeWidth={25}
                    totalScore={progressScoreData.total_score}
                    achievedScore={progressScoreData.achieved_score}
                />
                <p className="text-center body-2 text-font_color-body ">
                    Average performance across all your courses.
                </p>
            </div>
        </div>
    )
}
export default PerformanceScoreCard;