import React from 'react';

const TeacherCard = ({ teacher, onIntroSelect }) => {

    return (
        <div
            className="w-[250px] h-[338px] inline-block  text-primary overflow-hidden mx-[12px]">
            <div className="relative w-[250px] h-[300px]">
                <img
                    className="w-[250px] h-[300px] object-cover rounded-[16px]"
                    src={teacher.image_url}
                    alt="Teacher"
                />
                <div className="absolute inset-0 bg-gradient-to-b rounded-[16px] from-transparent to-[#333333]"></div>
                <button className="absolute flex items-center left-[60px] bottom-5 rounded-[6px] py-[10px] px-[20px] gap-[10px] bg-neutral-1 button3 text-primary-1 group">
                    <svg
                        className="transition-all duration-300 ease-in-out transform group-hover:order-2 group-hover:translate-x-[10px]"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.6665 2.66602V13.3327L13.3332 7.99935L4.6665 2.66602Z"
                            stroke="#34D795"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    <span className="transition-all duration-300 ease-in-out transform group-hover:order-1">
                        Watch Intro
                    </span>
                </button>

            </div>
            <p className="Heading-18 text-center items-center mt-2">{teacher.name}</p>
        </div>
    );
}

export default TeacherCard;