import React from "react";
import Sidebar from "../SideBar/Sidebar";
import { Content } from "./Content";
import Header from "../../SubComponents/Header";



export const Main = () => {
    return (
        <div className="h-[100vh] w-[100vw] flex flex-col overflow-hidden">
            <Header background={"bg-primary-1"} />
            <div className="flex flex-grow  overflow-hidden">
                <div className="hidden lg:block m-5">
                    <Sidebar />
                </div>
                <Content />
            </div>
        </div>
    )
}