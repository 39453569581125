import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MobileDrawer from './MobileDrawer';
import UserDropMenu from './UserDropMenu';
import AdminDropMenu from './AdminDropMenu';

const Header = ({ background }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const user = JSON.parse(localStorage.getItem("user"));
    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
    const navigate = useNavigate();
    const handleLogin = () => {
        navigate("/signin");
    }
    const handleSignUp = () => {
        navigate("/signup");
    }
    const handleLogoClick = () => {
        navigate("/");
    }



    return (

        <div className={`w-full ${background}`}>
            <div className="w-full flex flex-row items-center justify-between lg:px-[80px] lg:py-[20px] p-[20px]">
                <div className='flex gap-[10px]'>
                    <MobileDrawer
                        isOpen={isMenuOpen} toggleMenu={toggleMenu}
                    />
                    <img
                        onClick={handleLogoClick}
                        className="h-[32px] cursor-pointer w-[140px] lg:h-[41px] lg:w-[178px]" src="/LogoHeader.svg" alt="Logo" />
                </div>
                {user ? (
                    user.user.role === 2 ? (<UserDropMenu />) : (<AdminDropMenu />)
                ) : (
                    <div className="flex  gap-[16px]">
                        <button
                            className={`px-[16px] max-h-[48px] py-[8px] button2 bg-neutral-1 text-primary-1 rounded-[6px] lg:py-[13px] lg:text-neutral-1  lg:${background} lg:text-primary-1 lg: lg:button1 hover:text-primary-5 lg:border-neutral-1 lg:border-solid`}
                            onClick={handleLogin}>Sign In</button>
                        <button className={`hidden lg:block lg:border-solid border-2 border-neutral-1 py-2 px-6 text-neutral-1 rounded-[6px] lg:${background} button1`} onClick={handleSignUp}>
                            Sign Up Free
                        </button>

                    </div>
                )
                }


            </div >
        </div >
    );
};

export default Header;