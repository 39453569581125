import React from "react";




export const Support = () => {
    return (
        <div className="text-lg text-black">
            Support
        </div>
    )
}