import { PROFILE_FAIL, PROFILE_PENDING, PROFILE_SUCCESS } from "../Actions/Types";

const initialState = {
    getProfile: [],
};
const profile_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case PROFILE_SUCCESS:
            return {
                ...state,
                getProfile: payload.getProfile,
            };
        case PROFILE_PENDING:
            return {
                ...state,
                getProfile: payload.getProfile,
            };
        case PROFILE_FAIL:
            return {
                ...state,
                getProfile: initialState.getProfile,
            };

        default:
            return state;
    }
};
export default profile_reducer;