import React, { useState } from "react";
import DonutChart from "./DonutChart";
import { Select, MenuItem } from "@mui/material";

const CourseProgressCard = ({ courseProgressData }) => {
    const [selectedCourse, setSelectedCourse] = useState(courseProgressData[0] || null);

    const handleSelectChange = (event) => {
        const selectedCourseId = event.target.value;
        const course = courseProgressData.find((course) => course._id === selectedCourseId);
        setSelectedCourse(course);
    };
    return (
        <div className="flex flex-col rounded-[10px] p-[16px] text-font_color-primary gap-[16px] border border-neutral-4 lg:gap-[24px] lg:p-[24px] lg:h-[375px]">
            <div className="flex flex-col gap-[16px]">
                <p className="text-left Heading-18">
                    Course Progress
                </p>
                <div className="relative select-wrapper">
                    <Select
                        labelId="my-select-label"
                        value={selectedCourse ? selectedCourse._id : ""}
                        onChange={handleSelectChange}
                        className="w-full rounded-[6px] h-[40px]"
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 100,
                                },
                            },
                        }}
                    >
                        {courseProgressData.map((course) => (
                            <MenuItem
                                className="body-2"
                                key={course._id}
                                value={course._id}
                            >
                                <span
                                    style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        width: '100%',
                                    }}>{course.title}</span>
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </div>
            <div>
                <DonutChart
                    completed={selectedCourse.completed_sessions}
                    total={selectedCourse.total_sessions}

                />
            </div>
        </div>

    )
}
export default CourseProgressCard;

