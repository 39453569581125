import React, { useRef, useEffect, useState } from 'react';
import TeacherCardSelect from '../../SubComponents/TeacherCardSelect';


const ChooseTeacherModal = ({ isVisible, onClose, teachersData, courseId, isMorphing, onConfirmClick }) => {
    const carouselRef = useRef(null);
    const [showLeftGradient, setShowLeftGradient] = useState(true);
    const [showRightGradient, setShowRightGradient] = useState(true);
    const [selectedTeacherId, setSelectedTeacherId] = useState(null);
    const user = JSON.parse(localStorage.getItem("user"));


    const handleScroll = () => {
        const carousel = carouselRef.current;
        if (!carousel) return;

        const scrollLeft = carousel.scrollLeft;
        const scrollWidth = carousel.scrollWidth;
        const clientWidth = carousel.clientWidth;

        // Show or hide the left gradient
        if (scrollLeft === 0) {
            setShowLeftGradient(false);
        } else {
            setShowLeftGradient(true);
        }

        // Show or hide the right gradient
        if (scrollLeft + clientWidth >= scrollWidth) {
            setShowRightGradient(false);
        } else {
            setShowRightGradient(true);
        }
    };

    useEffect(() => {
        const carousel = carouselRef.current;
        if (carousel) {
            carousel.addEventListener('scroll', handleScroll);
            handleScroll();
        }

        return () => {
            if (carousel) {
                carousel.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);
    const handleScrollLeft = () => {
        const carousel = carouselRef.current;
        if (carousel) {
            carousel.scrollBy({
                left: -900,
                behavior: 'smooth'
            });
        }
    };

    const handleScrollRight = () => {
        const carousel = carouselRef.current;
        if (carousel) {
            carousel.scrollBy({
                left: 900,
                behavior: 'smooth'
            });
        }
    };
    const handleTeacherSelect = (teacherId) => {
        setSelectedTeacherId(teacherId);
    };

    const handleConfirmClick = () => {
        const body = {
            user_id: user.user._id,
            course_id: courseId,
            teacher_id: selectedTeacherId,
        };
        onConfirmClick(body);
    };

    return (
        <div
            className={`fixed inset-0 z-50 px-[16px] flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300 ${isVisible ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}
        >
            <div className="flex flex-col  gap-[16px] rounded-[16px] bg-white w-full p-[16px] lg:w-[910px] lg:p-[40px] lg:gap-[40px] lg:drop-shadow-2xl lg:rounded-[32px]">
                <div className="flex items-start justify-between">
                    <div>
                        <h3 className="Heading-20 lg:Heading-32">Choose Your Instructor </h3>
                        <p className="subtitle3 text-neutral-7 lg:subtitle1">Select from our experienced AI teachers to guide you through your learning journey.</p>
                    </div>
                    <button
                        className='h-[20px] w-[20px] lg:w-[24px] lg:h-[24px]'
                        onClick={onClose}>
                        <svg width="100%" height="100%" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.2 12.5001L22.35 3.3501C22.6875 3.0126 22.6875 2.4876 22.35 2.1501C22.0125 1.8126 21.4875 1.8126 21.15 2.1501L12 11.3001L2.84998 2.1501C2.51248 1.8126 1.98748 1.8126 1.64998 2.1501C1.31248 2.4876 1.31248 3.0126 1.64998 3.3501L10.8 12.5001L1.64998 21.6501C1.31248 21.9876 1.31248 22.5126 1.64998 22.8501C1.79998 23.0001 2.02498 23.1126 2.24998 23.1126C2.47498 23.1126 2.69998 23.0376 2.84998 22.8501L12 13.7001L21.15 22.8501C21.3 23.0001 21.525 23.1126 21.75 23.1126C21.975 23.1126 22.2 23.0376 22.35 22.8501C22.6875 22.5126 22.6875 21.9876 22.35 21.6501L13.2 12.5001Z" fill="#8C8C8C" />
                        </svg>
                    </button>

                </div>

                <div className="relative">
                    <div
                        ref={carouselRef}
                        className="overflow-x-auto whitespace-nowrap scroll-smooth"
                        style={{
                            scrollbarWidth: 'none',
                            msOverflowStyle: 'none',
                        }}
                    >
                        {teachersData.map((teacher) => (
                            <TeacherCardSelect
                                key={teacher._id}
                                teacher={teacher}
                                isChecked={teacher._id === selectedTeacherId}
                                onSelect={() => handleTeacherSelect(teacher._id)}

                            />
                        ))}
                    </div>
                    {showLeftGradient && (
                        <div className="absolute top-0 left-0 w-[80px] h-[338px] bg-[linear-gradient(270deg,rgba(255,255,255,0)_0%,rgba(255,255,255,0.76)_53.06%,#FFFFFF_100%)] items-center  lg:flex hidden">
                            <button
                                onClick={handleScrollLeft}
                                className="absolute top-1/2 w-[40px] h-[40px] flex items-center justify-center border border-neutral-5 rounded-full bg-white"
                            >
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.075 6.33711C14.7375 5.99961 14.2125 5.99961 13.875 6.33711L8.925 11.3996C8.5875 11.7371 8.5875 12.2621 8.925 12.5996L13.875 17.6621C14.025 17.8121 14.25 17.9246 14.475 17.9246C14.7 17.9246 14.8875 17.8496 15.075 17.6996C15.4125 17.3621 15.4125 16.8371 15.075 16.4996L10.6875 11.9996L15.075 7.53711C15.4125 7.19961 15.4125 6.63711 15.075 6.33711Z" fill="#434343" />
                                </svg>
                            </button>
                        </div>
                    )}
                    {showRightGradient && (
                        <div className="absolute top-0 right-0 w-[80px] h-[338px] bg-[linear-gradient(90deg,rgba(255,255,255,0)_0%,rgba(255,255,255,0.76)_53.06%,#FFFFFF_100%)] lg:flex hidden">
                            <button
                                onClick={handleScrollRight}
                                className="absolute top-1/2 right-0 transform w-[40px] h-[40px] flex items-center justify-center border border-neutral-5 rounded-full bg-white"
                            >
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.125 6.3376C9.7875 6.0001 9.2625 6.0001 8.925 6.3376C8.5875 6.6751 8.5875 7.2001 8.925 7.5376L13.3125 12.0001L8.925 16.4626C8.5875 16.8001 8.5875 17.3251 8.925 17.6626C9.075 17.8126 9.3 17.8876 9.525 17.8876C9.75 17.8876 9.975 17.8126 10.125 17.6251L15.1125 12.5626C15.45 12.2251 15.45 11.7001 15.1125 11.3626L10.125 6.3376Z" fill="#434343" />
                                </svg>
                            </button>
                        </div>
                    )}
                </div>
                <div className='justify-end flex'>
                    <button
                        className={`w-fit px-[20px] py-[10px] gap-[10px] rounded-[6px] button3 lg:button1 lg:px-[28px] lg:py-[13px]
                            ${selectedTeacherId ? 'bg-primary-1 text-neutral-1 hover:bg-primary-4' : 'bg-neutral-5 text-neutral-7'}`}
                        disabled={!selectedTeacherId}
                        onClick={handleConfirmClick}
                    >
                        Confirm and Continue
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ChooseTeacherModal;