import Lottie from 'lottie-react';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetSignIn } from '../Redux/Actions/AuthAction/SignInAction';

const MobileDrawer = ({ isOpen, toggleMenu }) => {
    const [animationData, setAnimationData] = useState(null);
    const user = JSON.parse(localStorage.getItem("user"));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [sidebar, setSidebar] = useState("");
    useEffect(() => {
        setSidebar(location.pathname);
    }, [location])
    const handleLogout = () => {
        localStorage.removeItem("user");
        dispatch(resetSignIn());
        navigate("/");
    }
    const handleSidebarClick = (path) => () => {
        if (!location.pathname.includes("app") && (!location.pathname.includes("admin"))) {
            if (user && user.user.role === 2) {
                navigate(`app/${path}`);
            } else if (user && user.user.role === 3) {
                navigate(`admin/${path}`);
            }

        } else {
            navigate(path);
        }
        toggleMenu();
    };
    useEffect(() => {
        // Fetch the Lottie JSON file from the public directory
        fetch('/mobile_drawer.json')
            .then((response) => response.json())
            .then((data) => setAnimationData(data))
            .catch((error) => console.error('Error loading Lottie animation:', error));
    }, []);

    const handleLogin = () => {
        toggleMenu();
        navigate("/signin");
    }
    const handleSignUp = () => {
        toggleMenu();
        navigate("/signup");
    }
    const handleLogoClick = () => {
        navigate("/");
    }

    return (
        <div>
            <div className="lg:hidden flex items-center">
                <button
                    onClick={toggleMenu}
                    className="text-white text-lg font-bold rounded-lg"
                >
                    <img src="/menu_icon.svg" alt="Menu Icon" className="h-[32px] w-[32px]" />
                </button>
            </div>

            <div
                className={`lg:hidden fixed top-0 left-0 w-[100vw] h-full bg-neutral-1 text-font_color-primary transform transition-transform duration-500 ease-in-out ${isOpen ? 'translate-x-0' : '-translate-x-full'
                    } z-50`}
            >
                <div className='flex w-full gap-[16px] px-[20px] py-[16px] border-b border-[#E2E8F0] items-center'>
                    <button
                        onClick={toggleMenu}
                        className='w-[24px] h-[24px]'>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.2 12.5001L22.35 3.3501C22.6875 3.0126 22.6875 2.4876 22.35 2.1501C22.0125 1.8126 21.4875 1.8126 21.15 2.1501L12 11.3001L2.84998 2.1501C2.51248 1.8126 1.98748 1.8126 1.64998 2.1501C1.31248 2.4876 1.31248 3.0126 1.64998 3.3501L10.8 12.5001L1.64998 21.6501C1.31248 21.9876 1.31248 22.5126 1.64998 22.8501C1.79998 23.0001 2.02498 23.1126 2.24998 23.1126C2.47498 23.1126 2.69998 23.0376 2.84998 22.8501L12 13.7001L21.15 22.8501C21.3 23.0001 21.525 23.1126 21.75 23.1126C21.975 23.1126 22.2 23.0376 22.35 22.8501C22.6875 22.5126 22.6875 21.9876 22.35 21.6501L13.2 12.5001Z" fill="#8C8C8C" />
                        </svg>
                    </button>
                    <button
                        onClick={handleLogoClick}
                    >
                        <img
                            src='/knnect_logo_green.svg'
                            alt='knnect logo'
                            className='w-[140px] h-[32px]'
                        />
                    </button>
                </div>

                {user ? (
                    <div className='flex flex-col gap-[24px] '>
                        <nav className="w-full py-[32px] flex flex-col gap-[24px]">
                            <ul className="flex flex-col gap-4 items-center px-3">
                                <li
                                    className={`w-full min-h-[44px] px-[16px] py-[12px] flex items-center gap-3 rounded-full font-medium cursor-pointer transition-all duration-500 hover:gap-4 hover:text-sidebar-black
        ${sidebar === "/app/dashboard" ? 'bg-sidebar-bg text-sidebar-blue hover:text-sidebar-blue' : 'text-sidebar-grey hover:bg-sidebar-bg'}
        ${isOpen ? 'opacity-100' : 'opacity-0'}
    `}
                                    onClick={handleSidebarClick("dashboard")}
                                >
                                    <span className="h-[20px] w-[20px]">
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            stroke="currentColor"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="transition-colors duration-300 ease-in-out"
                                        >
                                            <path
                                                d="M14.1663 11.6663V16.6663M11.6663 14.1663H16.6663M4.99967 8.33301H6.66634C7.58682 8.33301 8.33301 7.58682 8.33301 6.66634V4.99967C8.33301 4.0792 7.58682 3.33301 6.66634 3.33301H4.99967C4.0792 3.33301 3.33301 4.0792 3.33301 4.99967V6.66634C3.33301 7.58682 4.0792 8.33301 4.99967 8.33301ZM13.333 8.33301H14.9997C15.9201 8.33301 16.6663 7.58682 16.6663 6.66634V4.99967C16.6663 4.0792 15.9201 3.33301 14.9997 3.33301H13.333C12.4125 3.33301 11.6663 4.0792 11.6663 4.99967V6.66634C11.6663 7.58682 12.4125 8.33301 13.333 8.33301ZM4.99967 16.6663H6.66634C7.58682 16.6663 8.33301 15.9201 8.33301 14.9997V13.333C8.33301 12.4125 7.58682 11.6663 6.66634 11.6663H4.99967C4.0792 11.6663 3.33301 12.4125 3.33301 13.333V14.9997C3.33301 15.9201 4.0792 16.6663 4.99967 16.6663Z"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </span>

                                    <span
                                        className={`font-open_sans text-sm font-semibold leading-normal transition-all duration-500 ease-in-out`}
                                    >
                                        Dashboard
                                    </span>
                                </li>
                                {user && user.user.role === 2 && (
                                    <>
                                        <li
                                            className={`w-full min-h-[44px] px-[16px] py-[12px] flex items-center gap-3 rounded-full font-medium cursor-pointer transition-all duration-500 hover:gap-4 hover:text-sidebar-black
        ${(sidebar === "/app/courses" || sidebar === "/app/course-detail") ? 'bg-sidebar-bg text-sidebar-blue hover:text-sidebar-blue' : 'text-sidebar-grey hover:bg-sidebar-bg'}
        ${isOpen ? 'opacity-100' : 'opacity-0'}
    `}
                                            onClick={handleSidebarClick("courses")}
                                        >
                                            <span className='h-[20px] w-[20px] text-current'>
                                                <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="transition-colors duration-300 ease-in-out"
                                                >
                                                    <path
                                                        d="M18.2812 16.6562L15.625 2.8125C15.5312 2.3125 15.25 1.84375 14.8125 1.5625C14.375 1.28125 13.875 1.15625 13.3438 1.25L11.1875 1.65625C10.875 1 10.1875 0.53125 9.4375 0.53125H6.53125H3.625C2.53125 0.5625 1.65625 1.4375 1.65625 2.5V17.5C1.65625 18.5625 2.53125 19.4687 3.625 19.4687H6.53125H9.4375C10.5 19.4687 11.4062 18.5937 11.4062 17.5V8.53125L13.125 17.6562C13.2188 18.1562 13.5 18.625 13.9375 18.9062C14.25 19.125 14.6562 19.25 15.0312 19.25C15.1562 19.25 15.2812 19.25 15.4062 19.2187L16.75 18.9687C17.8125 18.75 18.5 17.7187 18.2812 16.6562ZM9.96875 2.5V6.1875H7.21875V1.96875H9.40625C9.71875 1.96875 9.96875 2.1875 9.96875 2.5ZM5.8125 14.1875H3.0625V6.6875H5.8125V14.1875ZM7.21875 7.59375H9.96875V12.125H7.21875V7.59375ZM3.625 1.96875H5.8125V5.3125H3.0625V2.5C3.0625 2.1875 3.3125 1.96875 3.625 1.96875ZM3.0625 17.5V15.5937H5.8125V18.0312H3.625C3.3125 18.0625 3.0625 17.8125 3.0625 17.5ZM9.4375 18.0625H7.25V13.5312H10V17.5C9.96875 17.8125 9.71875 18.0625 9.4375 18.0625ZM16.4688 17.5625L15.1562 17.8125C15 17.8437 14.875 17.8125 14.75 17.7187C14.625 17.625 14.5625 17.5 14.5312 17.375L11.75 3L13.625 2.65625C13.7812 2.625 13.9063 2.65625 14.0313 2.75C14.1563 2.84375 14.2188 2.96875 14.25 3.09375L16.9062 16.9375C16.9688 17.2187 16.7812 17.5 16.4688 17.5625Z"
                                                    />
                                                </svg>
                                            </span>

                                            <span
                                                className={`font-open_sans text-sm font-semibold leading-normal transition-all duration-500 ease-in-out ${!isOpen ? 'opacity-0' : 'opacity-100'}`}
                                            >
                                                Courses
                                            </span>
                                        </li>

                                        <li
                                            className={`w-full min-h-[44px] px-[16px] py-[12px] flex items-center gap-3 rounded-full font-medium cursor-pointer transition-all duration-500 hover:gap-4 hover:text-sidebar-black
                                    } ${(sidebar === "/app/my-learning" || sidebar === "/app/my-learning/enrolled-course" || sidebar === "/app/my-learning/enrolled-course/my-lesson") ? 'bg-sidebar-bg text-sidebar-blue hover:text-sidebar-blue' : 'text-sidebar-grey hover:bg-sidebar-bg'
                                                }`}
                                            onClick={handleSidebarClick("my-learning")}
                                        >
                                            <span className="h-[20px] w-[20px]">
                                                <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="transition-colors duration-300 ease-in-out"
                                                >
                                                    <path
                                                        d="M19 4.5625L10.25 1.40625C10.0938 1.34375 9.9375 1.34375 9.78125 1.40625L1.03125 4.5625C0.75 4.65625 0.5625 4.9375 0.5625 5.21875C0.5625 5.5 0.75 5.78125 1.03125 5.875L3.3125 6.71875V11.7188C3.3125 12.2812 3.6875 12.7812 4.21875 12.9688C6.59375 13.75 8.5 14.0625 10.2188 14.0625C11.0625 14.0625 11.875 13.9688 12.6875 13.8125V14.4062C11.8438 14.6875 11.2188 15.5 11.2188 16.4688C11.2188 17.6562 12.1875 18.6562 13.4063 18.6562C14.625 18.6562 15.5938 17.6875 15.5938 16.4688C15.5938 15.5313 14.9688 14.7188 14.125 14.4062V13.5C14.6875 13.3438 15.25 13.1563 15.8438 12.9688C16.375 12.7812 16.7188 12.2812 16.7188 11.75V6.71875L19 5.875C19.2812 5.78125 19.4688 5.5 19.4688 5.21875C19.4688 4.9375 19.2812 4.65625 19 4.5625ZM13.375 17.25C12.9375 17.25 12.5938 16.9063 12.5938 16.4688C12.5938 16.0313 12.9375 15.6875 13.375 15.6875C13.8125 15.6875 14.1562 16.0313 14.1562 16.4688C14.1562 16.9063 13.8125 17.25 13.375 17.25ZM10 2.8125L16.6875 5.21875L13.4688 6.375L11.2812 5.5625C10.9062 5.4375 10.5 5.625 10.375 5.96875C10.25 6.34375 10.4375 6.75 10.7812 6.875L11.4375 7.125L10 7.625L3.3125 5.21875L10 2.8125ZM4.71875 11.6562V7.21875L9.78125 9.03125C9.84375 9.0625 9.9375 9.0625 10.0312 9.0625C10.125 9.0625 10.1875 9.0625 10.2813 9.03125L12.7188 8.15625V12.4063C10.375 12.875 8.125 12.75 4.71875 11.6562ZM15.2813 11.6562C14.875 11.8125 14.4688 11.9375 14.0938 12.0312V7.65625L15.2813 7.21875V11.6562Z"
                                                        fill="currentColor"
                                                    />
                                                </svg>
                                            </span>

                                            <span
                                                className={`font-open_sans text-sm font-semibold leading-normal transition-all duration-500 ease-in-out ${isOpen ? 'opacity-100' : 'opacity-0'}`}
                                            >
                                                My Learning
                                            </span>
                                        </li>
                                    </>
                                )}

                            </ul>
                            {user && user.user.role === 2 && (
                                <>
                                    <hr className="w-full border-[#E2E8F0]" />
                                    <ul className="flex flex-col gap-4 items-center px-3">
                                        <li
                                            className={`w-full min-h-[44px] px-[16px] py-[12px] flex items-center gap-3 rounded-full font-medium cursor-pointer transition-all duration-500 hover:gap-4 hover:text-sidebar-black
                                    } ${sidebar === "/app/settings" ? 'bg-sidebar-bg text-sidebar-blue hover:text-sidebar-blue' : 'text-sidebar-grey hover:bg-sidebar-bg'
                                                }`}
                                            onClick={handleSidebarClick("settings")}
                                        >
                                            <span className="h-[20px] w-[20px]">
                                                <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M8.60386 3.59776C8.95919 2.13408 11.0408 2.13408 11.3961 3.59776C11.6257 4.54327 12.709 4.99198 13.5398 4.48571C14.8261 3.70199 16.298 5.17392 15.5143 6.46015C15.008 7.29105 15.4567 8.37431 16.4022 8.60386C17.8659 8.95919 17.8659 11.0408 16.4022 11.3961C15.4567 11.6257 15.008 12.709 15.5143 13.5398C16.298 14.8261 14.8261 16.298 13.5398 15.5143C12.709 15.008 11.6257 15.4567 11.3961 16.4022C11.0408 17.8659 8.95919 17.8659 8.60386 16.4022C8.37431 15.4567 7.29105 15.008 6.46016 15.5143C5.17392 16.298 3.70199 14.8261 4.48571 13.5398C4.99198 12.709 4.54327 11.6257 3.59776 11.3961C2.13408 11.0408 2.13408 8.95919 3.59776 8.60386C4.54327 8.37431 4.99198 7.29105 4.48571 6.46015C3.70199 5.17392 5.17392 3.70199 6.46015 4.48571C7.29105 4.99198 8.37431 4.54327 8.60386 3.59776Z"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M12.5 10C12.5 11.3807 11.3807 12.5 10 12.5C8.61929 12.5 7.5 11.3807 7.5 10C7.5 8.61929 8.61929 7.5 10 7.5C11.3807 7.5 12.5 8.61929 12.5 10Z"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </span>

                                            <span
                                                className={`font-open_sans text-sm font-semibold leading-normal transition-all duration-500 ease-in-out ${isOpen ? 'opacity-100' : 'opacity-0'}`}
                                            >
                                                Settings
                                            </span>
                                        </li>

                                        <li
                                            className={`w-full min-h-[44px] px-[16px] py-[12px] flex items-center gap-3 rounded-full font-medium cursor-pointer transition-all duration-500 hover:gap-4 hover:text-sidebar-black
                                    } ${sidebar === "/app/support" ? 'bg-sidebar-bg text-sidebar-blue hover:text-sidebar-blue' : 'text-sidebar-grey hover:bg-sidebar-bg'
                                                }`}
                                            onClick={handleSidebarClick("support")}
                                        >
                                            <span className="h-[20px] w-[20px]">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </span>

                                            <span
                                                className={`font-open_sans text-sm font-semibold leading-normal transition-all duration-500 ease-in-out ${isOpen ? 'opacity-100' : 'opacity-0'}`}
                                            >
                                                Support
                                            </span>
                                        </li>
                                    </ul>
                                </>
                            )}
                        </nav>
                        <div className="w-full h-[74px] py-4 px-4 flex items-center justify-between border-t border-gray-200 mt-auto">
                            <div
                                onClick={handleSidebarClick("profile")}
                                className={`flex gap-3 items-center rounded-full px-2 py-2 cursor-pointer transition-colors duration-500 ease-in-out ${sidebar === "/app/profile" ? 'bg-sidebar-bg' : 'hover:bg-sidebar-bg'
                                    }`}
                            >
                                <img
                                    src="/avatar.png" alt="profile"
                                    className='h-[40px] w-[40px] rounded-full'
                                />
                                <p className="text-title-black font-poppins leading-normal text-sm text-left font-semibold">
                                    {user.user.first_name}
                                </p>
                            </div>
                            <span
                                onClick={handleLogout}
                                className="group p-2 rounded-full cursor-pointer text-sidebar-grey hover:bg-sidebar-bg hover:text-sidebar-black transition-colors duration-300 ease-in-out">
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="group-hover:stroke-black"
                                >
                                    <path
                                        d="M14.1667 13.333L17.5 9.99967M17.5 9.99967L14.1667 6.66634M17.5 9.99967L5.83333 9.99967M10.8333 13.333V14.1663C10.8333 15.5471 9.71405 16.6663 8.33333 16.6663H5C3.61929 16.6663 2.5 15.5471 2.5 14.1663V5.83301C2.5 4.4523 3.61929 3.33301 5 3.33301H8.33333C9.71405 3.33301 10.8333 4.4523 10.8333 5.83301V6.66634"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </span>
                        </div>

                    </div>
                ) : (
                    <div className='flex flex-col gap-[60px] px-[20px]'>
                        <div className='flex flex-col gap-[32px]'>
                            <Lottie
                                animationData={animationData}
                                className='w-full h-[273px]'
                            />
                            <p className='body-2 text-center '>
                                Interactive lessons, real-time quizzes, and personalized feedback to help you master any subject.
                            </p>
                        </div>
                        <div className='flex flex-col gap-[16px]'>
                            <button className=' py-[13px] px-[20px] text-neutral-1 rounded-[6px] bg-primary-1 button2  hover:bg-primary-5'
                                onClick={handleSignUp}
                            >Sign Up Free</button>
                            <button className='border-solid border-2 border-primary-1  px-[20px] text-primary-1 button2 rounded-[6px] py-[13px] hover:text-primary-5 hover:border-primary-5'
                                onClick={handleLogin}
                            >Sign In</button>
                        </div>

                    </div>
                )}
                <div className='justify-center flex'>
                    <footer className="body-3 text-font_color-body text-center mt-10 lg:hidden">
                        <p>© 2024 Knnect. All Rights Reserved.</p>
                    </footer>
                </div>

            </div>
        </div>
    );
};

export default MobileDrawer;