import React, { useEffect, useState } from 'react';
import Header from "../SubComponents/Header";
import Lottie from 'lottie-react';
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_URL } from '../Constants';
const API_URL = BASE_URL


export const EmailVerification = () => {
    const [animationData, setAnimationData] = useState(null);
    const [invalidAnimationData, setInvalidAnimationData] = useState(null);
    const navigate = useNavigate();
    const { token } = useParams(); // Extract the token from the URL
    const [isVerified, setIsVerified] = useState(null); // To store verification status
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Fetch the Lottie JSON file from the public directory
        fetch('/email.json')
            .then((response) => response.json())
            .then((data) => setAnimationData(data))
            .catch((error) => console.error('Error loading Lottie animation:', error));
    }, []);
    useEffect(() => {
        // Fetch the Lottie JSON file from the public directory
        fetch('/invalid_link.json')
            .then((response) => response.json())
            .then((data) => setInvalidAnimationData(data))
            .catch((error) => console.error('Error loading Lottie animation:', error));
    }, []);

    useEffect(() => {
       
        const verifyToken = async () => {
            try {
                const response = await fetch(`${API_URL}verify-email/${token}`, {
                    method: 'GET',
                });
                const data = await response.json();
                console.log(data, "data of token");

                if (data.status === true) {
                    setIsVerified(true);
                    setTimeout(() => {
                        navigate('/signin');
                    }, 5000);

                } else {
                    setIsVerified(false);
                }
            } catch (error) {
                console.error('Error verifying token:', error);
                setIsVerified(false);
            } finally {
                setLoading(false);
            }
        };

        verifyToken();
    }, [token, navigate]);
    return (
        <div className="w-[100vw] h-[100vh] overflow-hidden">
            <Header background={"bg-primary-1"} />
            <div
                className="relative w-full h-[90vh]  rounded-md flex items-start lg:items-center justify-center overflow-hidden"
            >
                <div
                    className="hidden lg:block absolute inset-0"
                    style={{
                        backgroundImage: "url('/verify_bg_image.png')",
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        zIndex: -10
                    }}
                ></div>
                <div
                    className="lg:absolute lg:top-[center] lg:left-[center] p-[20px] lg:p-[32px] flex flex-col items-center gap-[12px] rounded-[16px] bg-white lg:shadow-[0px_12px_34px_0px_rgba(13,10,44,0.08),0px_34px_26px_0px_rgba(13,10,44,0.05)] lg:w-[504px]"
                >
                    {isVerified !== null && (

                        <div className='flex flex-col items-center gap-[16px] lg:gap-[32px]'>
                            {isVerified ? (

                                <>
                                    <Lottie
                                        animationData={animationData}
                                        className='h-[168px] lg:h-[300px]'
                                    />
                                    <p className="body-3 text-font_color-body justify-center text-center lg:body-1">
                                        Your email has been successfully verified. You will be redirected to the login page in a moment. If you can’t redirect, please click on the button below.
                                    </p>
                                    <button
                                        className="bg-primary-1 w-full rounded-[6px] hover:bg-primary-5 button3 px-[20px] py-[10px] text-neutral-1 flex items-center justify-center transition-all duration-[300ms] ease-out lg:py-[13px] lg:button1"
                                        onClick={() => window.location.href = '/signin'}
                                    >
                                        Continue to login
                                    </button>
                                </>
                            ) : (
                                <>
                                    <Lottie
                                        animationData={invalidAnimationData}
                                        className='h-[168px] lg:h-[300px]'
                                    />
                                    <p className="body-3 text-font_color-body justify-center text-center lg:body-1">
                                        The verification link you used is either invalid or has expired. You can log in, but consider verifying your email later.
                                    </p>
                                    <button
                                        className="bg-primary-1 w-full rounded-[6px] hover:bg-primary-5 button3 px-[20px] py-[10px] text-neutral-1 flex items-center justify-center transition-all duration-[300ms] ease-out lg:py-[13px] lg:button1"
                                        onClick={() => window.location.href = '/signin'}
                                    >
                                        Continue to login
                                    </button>
                                </>
                            )}
                            <footer className="mt-5 body-3 text-font_color-body text-center lg:hidden">
                                <p>© 2024 Knnect. All Rights Reserved.</p>
                            </footer>
                        </div>

                    )}

                </div>

            </div>

        </div>
    )
}