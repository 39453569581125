import React, { useState } from "react";
import { Select, MenuItem } from "@mui/material";
import EmailNotVerified from "./EmailNotVerified";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword, getProfile, sendVerificationEmail } from "../../Redux/Actions/UserActions";
import { TailSpin } from 'react-loader-spinner';
import VerificationResend from "../../SubComponents/VerificationResend";


export const Profile = () => {
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem("user"))
    const [showEmailNotVerified, setShowEmailNotVerified] = useState(false);
    const [showSuccessVerificationResend, setShowSuccessVerificationResend] = useState(false);
    const [formValues, setFormValues] = useState({
        first_name: "",
        last_name: "",
        email: "",
        institute: "",
        degree: "",
        currently_studying: true
    })
    const profile = useSelector((state) => state.profile_reducer.getProfile);
    const verificationEmailStatus = useSelector((state) => state.send_verification_email_reducer.sendVerificationEmail);
    const changePasswordStatus = useSelector((state) => state.forgot_password_reducer.forgotPassword);



    useEffect(() => {
        dispatch(getProfile());
    }, [])
    useEffect(() => {
        if (profile && profile !== "isLoading" && profile.length !== 0 && profile.result && profile.result.length !== 0) {
            const userProfile = profile.result;
            setFormValues({
                first_name: userProfile.first_name || "",
                last_name: userProfile.last_name || "",
                email: userProfile.email || "",
                institute: userProfile.institute || "",
                degree: userProfile.degree || "",
                currently_studying: userProfile.currently_studying || false
            });
            if (profile.result.is_verified === false) {
                setShowEmailNotVerified(true);
            }
        }
    }, [profile]);
    useEffect(() => {
        if (verificationEmailStatus && verificationEmailStatus !== "isLoading" && verificationEmailStatus.length !== 0 && verificationEmailStatus.status === true) {
            setShowEmailNotVerified(false);
            setShowSuccessVerificationResend(true);
        }
    }, [verificationEmailStatus]);
    useEffect(() => {
        if (changePasswordStatus && changePasswordStatus !== "isLoading" && changePasswordStatus.length !== 0 && changePasswordStatus.status === true) {
            setShowEmailNotVerified(false);
            setShowSuccessVerificationResend(true);
        }
    }, [changePasswordStatus]);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    };
    const handleSendVerificationEmail = () => {
        const body = {
            "email": user.user.email
        }
        dispatch(sendVerificationEmail(body));
    }
    const handleSendVerificationEmailClose = () => {
        setShowEmailNotVerified(false);
    }

    const handleChangePassword = () => {
        const body = {
            "email": user.user.email
        }
        dispatch(forgotPassword(body));
    }
    const handleClose = () => {
        setShowSuccessVerificationResend(false);
    }

    return (
        <div className="flex flex-col gap-[40px] text-font_color-primary mx-[10px]">
            {profile && profile === "isLoading" && (
                <div className='flex items-center justify-center h-full'>
                    <TailSpin color="#34D795" height={50} width={50} />
                </div>
            )}
            {profile && profile !== "isLoading" && profile.length !== 0 && profile.result && profile.result.length !== 0 && (
                <>
                    <div className="flex flex-col gap-[24px]">
                        {showSuccessVerificationResend && (
                            <VerificationResend text="A verification link has been sent to your email, please verify."
                                onClose={handleClose} />
                        )}
                        {showEmailNotVerified === true && (
                            <EmailNotVerified
                                onSendVerificationClick={handleSendVerificationEmail}
                                onCloseClick={handleSendVerificationEmailClose}
                            />
                        )}
                        <p className="text-left Heading-18 lg:Heading-24">Personal Details</p>
                        <div className="flex flex-col px-[16px] border-[1.6px] border-neutral-3 rounded-[4px] py-[24px] gap-[24px] lg:flex-row lg:gap-[64px] lg:px-[32px]">
                            <div className="flex flex-col items-center gap-[18px]">
                                <img
                                    className="w-[140px] h-[140px] rounded-full"
                                    src="/avatar.png"
                                />
                                <p className="Heading-18 lg:Heading-20">{`${formValues.first_name} ${formValues.last_name}`}</p>

                            </div>

                            <div className="grid gap-[16px] lg:gap-[24px] lg:max-w-[944px] lg:grid-cols-2">
                                {/* First Name */}
                                <div className="w-full flex flex-col gap-[10px] font-open_sans lg:gap-[5px]">
                                    <label className="subtitle2 lg:subtitle1">
                                        First Name
                                    </label>
                                    <div className="w-full h-[38px] bg-white border border-[#DFE4EA] rounded-[6px] flex items-center gap-[10px]">
                                        <input
                                            type="text"
                                            name="first_name"
                                            value={formValues.first_name}
                                            onChange={handleChange}
                                            placeholder="Enter your first name"
                                            className="w-full h-full bg-transparent  p-[8px_16px] border-none outline-none"
                                        />
                                    </div>
                                </div>

                                {/* Last Name */}
                                <div className="w-full flex flex-col gap-[10px] font-open_sans lg:gap-[5px]">
                                    <label className="subtitle2 lg:subtitle1">
                                        Last Name
                                    </label>
                                    <div className="w-full h-[38px] bg-white border border-[#DFE4EA] rounded-[6px] flex items-center gap-[10px]">
                                        <input
                                            type="text"
                                            name="last_name"
                                            value={formValues.last_name}
                                            onChange={handleChange}
                                            placeholder="Enter your last name"
                                            className="w-full h-full bg-transparent  p-[8px_16px] border-none outline-none"
                                        />
                                    </div>
                                </div>

                                {/* Email */}
                                <div className="w-full flex flex-col gap-[10px] font-open_sans lg:col-span-2 lg:gap-[5px]">
                                    <label className="subtitle2 lg:subtitle1">
                                        Email
                                    </label>
                                    <div className="w-full h-[38px] bg-white border border-[#DFE4EA] rounded-[6px] flex items-center gap-[10px]">
                                        <input
                                            type="email"
                                            name="email"
                                            value={formValues.email}
                                            disabled
                                            placeholder="Enter your email"
                                            className="w-full h-full bg-transparent p-[8px_16px] border-none outline-none"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                    <div className="flex flex-col gap-[24px]">
                        <p className="text-left Heading-18 lg:Heading-24">Security</p>
                        <div className="flex flex-col p-[16px] border-[1.6px] border-neutral-3 rounded-[4px] lg:py-[24px] gap-[24px] lg:flex-row lg:justify-between lg:px-[32px] lg:items-center">
                            <p className="text-font_color-body body-2 text-left lg:body-1">Keep your account secure by regularly updating your password.</p>
                            <button
                                onClick={handleChangePassword}
                                className=" border-2 button3 border-primary-1 w-fit px-[16px] py-[10px] text-primary-1 rounded-[6px] transition-colors duration-300 hover:text-primary-5 hover:border-primary-5 lg:button1 lg:px-[16px]">
                                Change Password
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-col gap-[24px]">
                        <p className="text-left Heading-18 lg:Heading-24">Highest Qualification</p>
                        <div className="flex flex-col p-[16px] border-[1.6px] border-neutral-3 rounded-[4px] lg:py-[24px] gap-[24px] lg:flex-row lg:px-[32px] lg:items-end">
                            <div className="w-full flex flex-col lg:flex-1 gap-[10px]">
                                <label className="subtitle2 lg:subtitle1">
                                    Institute Name
                                </label>
                                <Select
                                    labelId="my-select-label"
                                    // value={selectedCourse ? selectedCourse._id : ""}
                                    // onChange={handleSelectChange}
                                    className="w-full rounded-[6px] h-[40px]"
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 100,
                                            },
                                        },
                                    }}
                                >
                                    {/* {courseProgressData.map((course) => (
                            <MenuItem
                                className="body-2"
                                key={course._id}
                                value={course._id}
                            >
                                <span
                                    style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        width: '100%',
                                    }}>{course.title}</span>
                            </MenuItem>
                        ))} */}
                                </Select>
                            </div>
                            <div className="w-full flex flex-col lg:flex-1 gap-[10px]">
                                <label className="subtitle2 lg:subtitle1">
                                    Degree
                                </label>
                                <Select
                                    labelId="my-select-label"
                                    // value={selectedCourse ? selectedCourse._id : ""}
                                    // onChange={handleSelectChange}
                                    className="w-full rounded-[6px] h-[40px]"
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 100,
                                            },
                                        },
                                    }}
                                >
                                    {/* {courseProgressData.map((course) => (
                            <MenuItem
                                className="body-2"
                                key={course._id}
                                value={course._id}
                            >
                                <span
                                    style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        width: '100%',
                                    }}>{course.title}</span>
                            </MenuItem>
                        ))} */}
                                </Select>
                            </div>
                            <div className="w-full flex flex-col lg:flex-1 gap-[10px]">
                                <label className="subtitle2 lg:subtitle1">
                                    Graduation Date
                                </label>
                                <Select
                                    labelId="my-select-label"
                                    // value={selectedCourse ? selectedCourse._id : ""}
                                    // onChange={handleSelectChange}
                                    className="w-full rounded-[6px] h-[40px]"
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 100,
                                            },
                                        },
                                    }}
                                >
                                    {/* {courseProgressData.map((course) => (
                            <MenuItem
                                className="body-2"
                                key={course._id}
                                value={course._id}
                            >
                                <span
                                    style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        width: '100%',
                                    }}>{course.title}</span>
                            </MenuItem>
                        ))} */}
                                </Select>
                            </div>

                            <div className="w-full flex lg:flex-1 gap-[10px] py-[10px]">
                                <div class="inline-flex items-center">
                                    <label class="flex items-center cursor-pointer relative">
                                        <input type="checkbox" checked={true} class="peer h-[20px] w-[20px] cursor-pointer transition-all appearance-none rounded hover:shadow-md border border-neutral-6 checked:bg-primary-1 checked:border-primary-1" id="check4" />
                                        <span class="absolute text-white opacity-0 peer-checked:opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor" stroke="currentColor" stroke-width="1">
                                                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                                            </svg>
                                        </span>
                                    </label>
                                </div>
                                <p className="text-font_color-body body-2 text-left lg:body-1">I currently study here</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end">
                        <button
                            className="button3 px-[20px] py-[10px] gap-[10px] bg-primary-1 text-neutral-1 rounded-[6px] hover:bg-primary-4 lg:button1 lg:px-[28px] lg:py-[12px]"
                        >
                            Save Changes
                        </button>
                    </div>
                </>
            )}

        </div >
    )
}